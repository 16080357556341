<template>
<div class="wrapper">
  <topbar2></topbar2>
  <header2></header2>
  <div class="content-wrapper">
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h3 class="m-0">Edit Order</h3>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Edit Order</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-2">&nbsp;</div>
          <div class="col-lg-8 connectedSortable">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title"> <i class="fas fa-chart-pie mr-1"></i> Edit Order </h3>
                {{invoice_id}} </div>
              <div class="card-body">
                <div class="tab-content p-0">
                  <div class="chart tab-pane active">
                    <div>
                      <div class="row">
                        <div class="col-md-6">
                          <label>Booking Date</label>
                          <input type="date" class="form-control" name="pickup_date" id="pickup_date" v-model="pickup_date"/>
                        </div>
                        <div class="col-md-6">
                          <label>Delivery Date</label>
                          <datepicker class="form-control" type="hidden" v-model="ss" />
                          <input type="date" class="form-control" name="delivery_date" id="delivery_date" v-model="delivery_date"/>
                        </div>
                      </div>
                      <div class="hgt">&nbsp;</div>
                      <div class="row">
                        <div class="col-md-6">
                          <label>Name</label>
                          <input type="text" class="form-control" name="name" id="name" v-model="name"/>
                        </div>
                        <div class="col-md-6">
                          <label>Mobile No</label>                          
                          <input type="text" class="form-control" name="mobile_no" id="mobile_no" v-model="mobile_no"/>
                        </div>
                      </div>
                      <div class="hgt">&nbsp;</div>
                      <div>
                        {{item_list}}
                        <div class="row">
                          <div class="col-md-4">
                            <label>Item List</label>
<select v-model="item" @change="getItem()" class="form-control">
<option value="" disabled hidden>Select Item</option>
<option v-for="service in serviceList" :key="service.id" v-bind:value="{ itemid: service.id, itemamount: service.amount, itemname: service.name }">{{service.name}}</option>
</select>
                          </div>
                          <div class="col-md-3">
                            <label>Qty.</label>
<div>
<button class="btn btn--minus minus" @click="changeCounter('-1')" type="button" name="button"><i class="fas fa-minus"></i></button>
<input class="quantity" type="number" name="sale_quantity" v-model="counter">
<button class="btn btn--plus plus" @click="changeCounter('1')" type="button" name="button"><i class="fas fa-plus"></i></button>
</div>                            
<!--<input v-model="sale_quantity" @change="updateQuantity" type="number" class="form-control" name="sale_quantity" id="sale_quantity" placeholder="Quantity">-->
                            <input v-model="sale_rate"  @change="updateRate" type="hidden" step="any" class="form-control" name="sale_rate" id="sale_rate" placeholder="Rate">
                          </div>
                          <div class="col-md-3">
                            <label>Amount</label>
                            <input v-model="sale_total" type="number" step="any" class="form-control" name="sale_total" id="sale_total" placeholder="Total Price">
                          </div>
                          <div class="col-md-2">
                            <label>&nbsp;</label>
                            <br>
                            <button @click="addCat" class="btn btn-success">Add More</button>
                          </div>
                        </div>                        
                        <div class="row" v-for="(cat,n) in myArray" v-bind:key="n.id">
                          <div class="col-md-4">
                            <input readonly class="form-control" type="text" v-model="cat[0].name" />
                          </div>
                          <div class="col-md-3">
                            <input readonly class="form-control" type="text" v-model="cat[0].quantity" />
                          </div>
                          <div class="col-md-3">
                            <input readonly class="form-control" type="text" v-model="cat[0].amount" />
                          </div>
                          <div class="col-md-2">
                            <button @click="removeCat(n)" class="btn btn-danger btn-sm"><i class="fa fa-times-circle"></i></button>
                          </div>
                        </div>
                        <div class="row" v-for="d in item_listedit" v-bind:key="d.id">
                          <div class="col-md-4 editlist">
                            <input readonly class="form-control" type="text" v-model="d.item_name" />
                          </div>
                          <div class="col-md-3 editlist">
                            <input readonly class="form-control" type="text" v-model="d.quantity" />
                          </div>
                          <div class="col-md-3 editlist">
                            <input readonly class="form-control" type="text" v-model="d.amount" />
                          </div>
                          <div class="col-md-2"></div>
                        </div>
                        <div>
                          <hr>
                        </div>
                      </div>                      
                      <div class="row">
                         <div class="col-md-4">
                           <label>&nbsp;</label>
<div class="example ex1">                          
<label class="radio green">                          
<input type="radio" v-model="delivery_type" name="delivery_type" value="normal" checked>
<span>Normal</span>
</label>
                          
<label class="radio green">                          
<input type="radio" v-model="delivery_type" name="delivery_type" value="Argent">
<span>Urgent</span>
</label>
</div>
                          </div>
                        <div class="col-md-3">
                          <label>Extra Charge List </label>
                          <br>                          
<select v-model="extraitemId" @change="getExtra()" class="form-control">
<option v-for="extra in extraList" :key="extra.id" :value="extra.amount"> {{extra.name}} </option>
</select>
                        </div>
                        <div class="col-md-3">
                          <label>Extra Charge</label>
                          <br>
<input v-model="disamount" type="number" step="any" class="form-control" readonly name="disamount" id="disamount" placeholder="Extra Charge">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-7" style="text-align:right;">
                          <div class="hgt">&nbsp;</div> 
                          <label>Discount</label>
                        </div>
                        <div class="col-md-3">
                          <div class="hgt">&nbsp;</div>                        
                          {{discount}}
                          <input v-model="extra_plus" @change="updatePlus" type="number" class="form-control" name="extra_plus" id="extra_plus" placeholder="Discount">
                          <input v-model="extra"  @change="updateRateplus" type="hidden" step="any" class="form-control" name="extra" id="extra" placeholder="Discount">
                        </div>
                      </div>

                      <div class="row">
                          <div class="col-md-7" style="text-align:right;">
                           <div class="hgt">&nbsp;</div> 
                          <label>Grand Total:</label>
                          </div>
                          <div class="col-md-3">
                             <div class="hgt">&nbsp;</div> 
                            {{grandtotal}} 
                          </div>
                      </div>
                      <div class="row">
                           <div class="col-md-7" style="text-align:right;">  
                            <div class="hgt">&nbsp;</div>                          
                          <label>Pay Amount</label>                          
                        </div>
                        <div class="col-md-3">
                           <div class="hgt">&nbsp;</div> 
                          {{paid_amount}}
                          <input v-model="payamount" @change="updatePayamount" type="number" class="form-control" name="payamount" id="payamount" placeholder="Pay Amount">
                        </div>
                      </div>

                      <div class="row">
                       
                        <div class="col-md-7" style="text-align:right;">                         
                         <div class="hgt">&nbsp;</div> 
                          <label>Balance:</label>                          
                        </div>
                        <div class="col-md-3">
                          <div class="hgt">&nbsp;</div> 
                          {{balance}}
                          <!--netamount--->
                          <input v-model="netamount" type="hidden" step="any" name="netamount" id="netamount">
                          <!--grandtotal--->
                          <input v-model="grandtotal" type="hidden" step="any" name="grandtotal" id="grandtotal">
                          <!--balance--->
                          <input v-model="balance" type="hidden" step="any" name="balance" id="balance">
                        </div>
                        
                      </div>

                      <div>&nbsp;</div>
                                           
                      
                    </div>
                  </div>
                </div>
                                        <div class="row">
                          <div class="col-md-7"> &nbsp; </div>
                          <div class="col-md-3">
                <button v-on:click="submitorder()" class="btn btn-success">Submit</button>
                          </div>
                                        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer2></footer2>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import Datepicker from 'vue3-datepicker'
import Header from './../Header.vue'
import Footer from './../Footer.vue'
import Topbar from './../Topbar.vue'
import axios from "axios";
export default {  
  extends:defineComponent,  
    components:{        
        "header2": Header,
        "footer2": Footer,
        "topbar2": Topbar,
        Datepicker    
      },
  name: 'editservice',  
    data(){          
        return {
            company_id:this.$store.state.auth.user.data[0].company_id,
            counter: 1,
            //id: this.$route.params.id,
            invoice_id: this.$route.params.id,
            user_id: '1',            
            mobile_no: '',
            name: '',            
            serviceList: [], 
            extraList: [],           
            item: "",            
            extraitem: {},
            extraitemId:0,
            sale_rate: '0',
            sale_quantity: '0', 
            sale_total: '0',  
            extra: '0', 
            extra_plus: '',            
            totalextra: '0',  
            value1: '',
            value2: '', 
            totalvalue: '', 
            payamount: '',  
            pickup_date: this.pickup_date,
            delivery_date: this.delivery_date,
            order_type: this.order_type,
            delivery_type: this.order_type,
            cats:[],
            myArray:[],
            newCat:null,
            itemid: '', 
            itemamount: '', 
            itemname: '', 
            extraid: '',
            extraname: '',
            extraamount: 0,
            list:[],  
            item_list:[], 
            item_listedit:[],
            additional_charge:[], 
            item_id:'',
            item_name:'',
            disamount:this.disamount,
            ext:[], 
            olditemid:'', 
            olditem_name:'',
            oldquantity:'',
            listtwo:[],          
            form: {
                user_id: '1',                
                mobile_no: '',                          
            }
        }
    },
computed: {
  Sumedit(){ return this.item_listedit.reduce( (Sumedit, d) => d.amount + Sumedit  ,0);},
  Sum(){ return this.myArray.reduce( (Sum, cat) => cat[0].amount + Sum  ,0);},        
  balance: function() {
      return parseInt(this.disamount) + parseInt(this.extra) - parseInt(this.extra_plus) - parseInt(this.payamount) + parseInt(this.Sum) + parseInt(this.Sumedit);
  }, 
  grandtotal: function() {
      return parseInt(this.extra) - parseInt(this.extra_plus) + parseInt(this.Sum) + parseInt(this.disamount) + parseInt(this.Sumedit);
  },
  netamount: function() {
      return parseInt(this.Sum) + parseInt(this.Sumedit) - parseInt(this.extra_plus);
  }
},
    mounted() {     

      axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/getorderdetails",  {
    invoice_id: this.$route.params.id,
    company_id: this.company_id,
	})
      .then(res => {
        //alert(res.data.data)
        var resData = res.data.data[0];
        this.invoice_details = resData.invoice_details;    
        this.item_listedit = resData.item_list;
        this.additional_charge = resData.additional_charge;   
        
        this.name = resData.invoice_details[0].name
        this.pickup_date = resData.invoice_details[0].pickup_date
        this.booking_date = resData.invoice_details[0].booking_date
        this.delivery_date = resData.invoice_details[0].delivery_date
        this.extra_plus = resData.invoice_details[0].discount
        this.net_amount = resData.invoice_details[0].net_amount
        this.grand_amount = resData.invoice_details[0].grand_amount
        this.payamount = resData.invoice_details[0].paid_amount
        this.balance = resData.invoice_details[0].balance
        this.order_type = resData.invoice_details[0].order_type
        this.client_id = resData.invoice_details[0].client_id
        this.mobile_no = resData.invoice_details[0].mobile_no
        this.invoice_no = resData.invoice_details[0].invoice_no 

        this.item_id = resData.additional_charge[0].item_id
        this.item_name = resData.additional_charge[0].item_name
        this.disamount = resData.additional_charge[0].amount      
      

        this.extraitem.extraid = resData.additional_charge[0].item_id;
        this.extraitem.extraamount = resData.additional_charge[0].amount;
        this.extraitem.extraname = resData.additional_charge[0].item_name;
        this.extraitemId = resData.additional_charge[0].amount;

        this.olditemid = resData.item_list[0].item_id;
        this.olditem_name = resData.item_list[0].item_name;
        this.oldquantity = resData.item_list[0].quantity;
        this.oldamount = resData.item_list[0].amount;
        
        this.list = {id:this.olditemid,name:this.olditem_name, quantity:this.oldquantity, amount:this.oldamount}
        this.item_list.push(this.list); 
        this.extral = [{id:this.extraitem.extraid,name:this.extraitem.extraname,amount:this.extraitem.extraamount}]


        console.log(resData)
      })
      .catch(error => {        
        console.log(error)
         // Manage errors if found any
      })

	axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/servicelist",  {
    company_id: this.company_id, 
    page:'all'   
	})
      .then(res => {
        //alert(res.data.data)
        this.serviceList = res.data.data;        
        //console.log(this.serviceList)
      })
      .catch(error => {        
        console.log(error)
         // Manage errors if found any
      })

      axios.post("http://laundry.ezeehost.com:4909/ClientIspmateApi/extrachargelist",  {
      company_id: this.company_id,   
	})
      .then(res => {
        //alert(res.data.data)
        this.extraList = res.data.data;        
        //console.log(this.extraList)
      })
      .catch(error => {        
        console.log(error)         
      }) 


      if(localStorage.getItem('cats')) {
      try {
        this.cats = JSON.parse(localStorage.getItem('cats'));
      } catch(e) {
        localStorage.removeItem('cats');
      }
    }

  },

    methods: {    
      showsuccessmsg(msgData){
       createToast(msgData,{
          type: 'success',position: 'top-right',showIcon: true,timeout: 3000
        })
     },
     showfailedmsg(msgData){
       createToast(msgData,{
          type: 'danger',position: 'top-right',showIcon: true,timeout: 3000
        })
     }, 
       changeCounter: function (num) {
      this.counter += +num;
      this.sale_total = this.counter * this.sale_rate
      //console.log(this.counter);
      !isNaN(this.counter) && this.counter > 0 ? this.counter : this.counter = 0;

    }, 
        updateQuantity(event) {
            this.sale_quantity = event.target.value
            this.sale_total = this.sale_quantity * this.sale_rate
        },
        updateRate(event) {
            this.sale_rate = event.target.value
            this.sale_total = this.sale_quantity * this.sale_rate
        }, 
        updatePlus(event) {            
            this.extra_plus = event.target.value
            this.total = this.total - this.extra_plus            
        },
        updatePayamount(event) {            
            this.payamount = event.target.value            
            //console.log(this.payamount)         
        },
        updateRateplus(event) {
            this.extra = event.target.value
            this.discount = this.extra_plus + this.extra            
        },                      

        getItem(){
            this.itemid = this.item.itemid;        
            this.sale_rate = this.item.itemamount;        
            this.sale_total = this.item.itemamount;  
            this.base_price = this.item.itemamount; 
            //console.log(this.sale_total)     
            
        },
        getExtra(){            
            this.extra = this.extraitemId;        
            this.disamount = this.extraitemId;            
            console.log(this.disamount)               
        },


addCat() { 
          
    this.list = {id:this.item.itemid,name:this.item.itemname, quantity:this.counter, amount:this.sale_total, base_price:this.base_price}    
       
    this.cats = [{name:this.item.itemname, quantity:this.counter, amount:this.sale_total}]       
    
    this.myArray.push(this.cats);    
    this.item_list.push(this.list); 
                                    
    console.log(this.list);    

    this.saveCats();
},
    removeCat(x) {
      this.myArray.splice(x,1);
      this.saveCats();
    },
    saveCats() {
      let parsed = JSON.stringify(this.myArray);
      localStorage.setItem('myArray', parsed);
    },

       
        
        additem(e){
            //alert('dfdsfd');
          e.preventDefault() // it prevent from page reload
          localStorage.setItem('sale_quantity',this.sale_quantity)
          localStorage.setItem('sale_total',this.sale_total)

          //console.log(this.sale_quantity);
        },


        submit(){        
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/searchclient', {user_id: this.user_id,mobile_no: this.mobile_no} , {
              user_id: '1',
              mobile_no: this.mobile_no,
            })
                .then(response => {                
                this.name=response.data.data[0].name;
                this.balance=response.data.data[0].balance;
                //console.log(response.data);                
                //return response
                });
        },

        submitorder(){              
           
            axios.post('http://laundry.ezeehost.com:4909/ClientIspmateApi/updateorder', {
              user_id: this.user_id, 
              company_id: this.company_id, 
              name: this.name,
              invoice_id: this.$route.params.id,           
              pickup_date: this.pickup_date,
              delivery_date: this.delivery_date,
              delivery_type: this.order_type,
              grand_total: this.grandtotal,
              discount: this.extra_plus,
              net_amount: this.netamount,
              paid_amount: this.payamount,
              bal_amount: this.balance,
              item_list: this.item_list,
              extra_item: this.extral
              })
                .then(response => {   
                  this.showsuccessmsg(response.data.response);                  
                  localStorage.setItem('invoice_id', response.data.data.invoice_id)
                  console.log(response.data);
                  //return response
                }).catch(error => { 
                  this.showfailedmsg('Something went wrong');       
                  console.log(error)        
      });
        },


    }
}
</script>
<style>
.editlist { margin-top:5px; }
.minus { border:1px solid #F275BF !important; 
background: #F275BF !important;
color: #fff !important;
border-radius: 5px 0 0 5px !important;
}
.plus { border:1px solid #2BD6C5 !important;
background:#2BD6C5 !important; 
color: #fff !important;
border-radius: 0 5px 5px 0 !important;
}
.quantity { width: 47%; padding: 6px; border: 1px solid #ced4da; position: relative; top: 2px; text-align: center;}
.hgt { height: 10px;}

  @import '../../assets/dist/css/daterangepicker.css'  
</style>

